var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _vm.user != null
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mt-4",
                      attrs: { header: "Gebruiker - " + _vm.user.Username }
                    },
                    [
                      _c("table", { staticClass: "table table-bordered" }, [
                        _c("tr", [
                          _c("th", [_vm._v("Username")]),
                          _c("td", [_vm._v(_vm._s(_vm.user.Username))]),
                          _c("th", [_vm._v("Type")]),
                          _c("td", [_vm._v(_vm._s(_vm.user.Type))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Naam")]),
                          _c("td", [_vm._v(_vm._s(_vm.user.ContactName))]),
                          _c("th", [_vm._v("Status")]),
                          _c("td", [_vm._v(_vm._s(_vm.user.Status))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("E-mail")]),
                          _c("td", [_vm._v(_vm._s(_vm.user.Email))]),
                          _c("th", [_vm._v("Allow Analytics")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("yesno")(_vm.user.AllowAnalytics))
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Contract")]),
                          _c(
                            "td",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "contract-detail",
                                      params: { id: _vm.user.ContractID }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.user.Contract.Name) +
                                      " (" +
                                      _vm._s(_vm.user.Contract.ContractID) +
                                      ") "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("th", [_vm._v("Allow Royalties")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("yesno")(_vm.user.AllowRoyalty))
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Contract naam")]),
                          _c("td", [_vm._v(_vm._s(_vm.user.Contract.Name))]),
                          _c("th", [_vm._v("Allow Trends")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("yesno")(_vm.user.AllowTrends))
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Contract Bedrijf")]),
                          _c("td", [_vm._v(_vm._s(_vm.user.Contract.Company))]),
                          _c("th", [_vm._v("Allow Manage Release Concepts")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("yesno")(
                                  _vm.user.AllowManageReleaseConcepts
                                )
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Aangemaakt")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("datetimeFormat")(_vm.user.Created))
                            )
                          ]),
                          _c("th", [_vm._v("Aangemaakt door")]),
                          _c("td", [_vm._v(_vm._s(_vm.user.CreatedBy))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Laatst gewijzigd")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("datetimeFormat")(_vm.user.Modified)
                              )
                            )
                          ]),
                          _c("th", [_vm._v("Aangepast door")]),
                          _c("td", [_vm._v(_vm._s(_vm.user.ModifiedBy))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Laatste inlog")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("datetimeFormat")(_vm.user.LastLogin)
                              )
                            )
                          ]),
                          _c("th"),
                          _c("td")
                        ])
                      ]),
                      _vm.user.Status == "new"
                        ? _c(
                            "b-button",
                            { on: { click: _vm.sendWelcomeMail } },
                            [_vm._v("Verstuur welkomstmail")]
                          )
                        : _vm._e(),
                      _vm.user.Status != "new"
                        ? _c(
                            "b-button",
                            { on: { click: _vm.sendPasswordResetMail } },
                            [_vm._v("Verstuur wachtwoord reset mail")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }